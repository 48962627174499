import { IElement, IPage } from '../types'

/**
 * Recursively flattens all children elements in a nested structure.
 * @param {IElement[]} elements - The list of elements to flatten.
 * @returns {IElement[]} - The flat list of all children elements.
 */
const flattenElements = (elements: IElement[]): IElement[] => {
  let result: IElement[] = []
  for (const element of elements) {
    result.push(element)
    if (element.children && element.children.length > 0) {
      result = result.concat(flattenElements(element.children))
    }
  }
  return result
}

/**
 * Flattens all children elements in the pages.
 * @param {IPage[]} pages - The list of pages to flatten.
 * @returns {IElement[]} - The flat list of all children elements.
 */
export const flatChildrensv2 = (pages: IPage[]): IElement[] => {
  return pages.reduce((acc, page) => {
    if (page.children && page.children.length > 0) {
      acc = acc.concat(flattenElements(page.children))
    }
    return acc
  }, [] as IElement[])
}

/**
 * Recursively find an element by its ID in a nested structure.
 * @param {IElement[]} elements - The list of elements to search.
 * @param {string} id - The ID of the element to find.
 * @returns {IElement | null} - The found element or null if not found.
 */
export const findElementById = (
  elements: IElement[],
  id: string
): IElement | null => {
  for (const element of elements) {
    if (element.id === id) {
      return element
    }
    if (element.children) {
      const found = findElementById(element.children, id)
      if (found) {
        return found
      }
    }
  }
  return null
}

/**
 * Recursively find the parent element of a given element ID.
 * @param {IElement[]} elements - The list of elements to search.
 * @param {string} id - The ID of the element whose parent is to be found.
 * @returns {IElement | null} - The parent element or null if not found.
 */
export const getParentElement = (
  elements: IElement[],
  id: string
): IElement | null => {
  for (const element of elements) {
    if (element.children && element.children.some(child => child.id === id)) {
      return element
    }
    if (element.children) {
      const found = getParentElement(element.children, id)
      if (found) {
        return found
      }
    }
  }
  return null
}

/**
 * Check if two elements are siblings (i.e., they share the same parent).
 * @param {IElement[]} elements - The list of elements to search.
 * @param {string} id1 - The ID of the first element.
 * @param {string} id2 - The ID of the second element.
 * @returns {boolean} - True if the elements are siblings, false otherwise.
 */
export const checkIfSibling = (
  elements: IElement[],
  id1: string,
  id2: string
): boolean => {
  for (const element of elements) {
    if (
      element.children &&
      element.children.some(child => child.id === id1) &&
      element.children.some(child => child.id === id2)
    ) {
      return true
    }
    if (element.children) {
      const found = checkIfSibling(element.children, id1, id2)
      if (found) {
        return found
      }
    }
  }
  return false
}
