import {
  DocumentStatus,
  DocumentTypeEnum,
  PaymentStatus,
  ScheduleRRuleOptions,
  TimeZoneEnum,
} from '../../index'
import {
  ComponentOptionsTypes,
  FillableField,
  GroupComponentOptionsTypes,
  GroupFieldsOptions,
  IComponent,
  IElement,
  IPage,
  IPricingTable,
  IRecipient,
  IResponsiveStyles,
  PriceTypeEnum,
  RecipientEntityTypeEnum,
} from '../index'

import { Editor } from '@tiptap/vue-3'
import { ICustomFieldsLink, PaymentSchedule } from '../components'
import { IDocumentNotificationSettings } from '../components/document-notification-settings.schema'

export enum RecipientCategory {
  RECIPIENT = 'recipient',
  CC = 'cc',
  BCC = 'bcc',
}

export type Option<T> = {
  label: string
  value: T
}
export type Options<T> = Option<T>[]

export interface ICustomVariable {
  fieldKey: string
  type?: string
  value: string
}

export interface IVersionHistory {
  createdAt: Date
  downloadUrl: string
  id: string
  name: string
  path: string
  updatedBy: string
}

export interface IUpdateElementOption {
  id: string
  key: string
  value: ComponentOptionsTypes | number
  type?: string
}

export interface IUpdateGroupOption {
  id: string
  key: string
  value: GroupComponentOptionsTypes | number
}

export interface AppStore {
  activeElementId: string | null
  editor: typeof Editor | undefined
  pages: IPage[]
  hover: boolean
  focusedElement: IElement | undefined
  updateEditors: boolean
  undoRedoStack: Array<Array<IElement>>
  undoRedoStackPointer: number
  undoSelected: boolean
  moveElementDragState: boolean
  status: DocumentStatus
  flatCustomValues: Record<string, string>
  groups: GroupFields<GroupFieldsOptions>[]
  activeGroup: GroupFields<GroupFieldsOptions> | null
  customValueLinkage: Record<string, ICustomFieldsLink>
}

export interface TemplateAppStore extends AppStore {
  isPublished: boolean
}

export type CustomDocumentFields = {
  name: string
  recipients: IRecipient[]
  variables: ICustomVariable[]
}

export type IDocument = CustomDocumentFields & {
  pages: IPage[]
  createdAt: Date
  deleted: boolean
  status: DocumentStatus
  tags: string[]
  type: DocumentTypeEnum
  updatedAt: Date | null
  updatedBy: string | { name: string; email: string }
  version: number
  versionHistory: IVersionHistory[]
  pricingTable: []
  locationId: string
  invoices?: string[]
  grandTotal?: IGrandTotal
  enableSigningOrder?: boolean
  enableDirectPayment?: boolean
  enableAutoSendInvoice?: boolean
  pricingTables?: IPricingTable[]
  fillableFields: FillableField[]
  paymentStatus?: PaymentStatus
  timezone: {
    zone: TimeZoneEnum
    abbreviation: string
  }
  sentBy?: { name: string; email: string }
  groups?: GroupFields<GroupFieldsOptions>[]
  paymentLiveMode?: boolean
  customValueLinkage?: Record<string, ICustomFieldsLink>
  ccRecipients?: ccRecipient[]
  documentRevision?: number
  notificationSettings?: IDocumentNotificationSettings
}
export type IDocumentTemplate = IDocument & {
  isPublished?: boolean
  publishedAt?: Date
}

export interface GroupFields<T> {
  type: string
  version: number
  id: string
  children: { pageId: string; elementId: string }[]
  responsiveStyles: IResponsiveStyles
  component: IComponent<T>
}

export interface LinkReference {
  referenceId: string
  documentId: string
  recipientId: string
  entityName: RecipientEntityTypeEnum
  createdBy: string
  recipientCategory: RecipientCategory
}
export type IGrandTotal = {
  currency: string
  amount: number
  discountPercentage: number
}

export type IDocumentViewer = Pick<
  IDocument,
  | 'name'
  | 'pages'
  | 'type'
  | 'status'
  | 'locationId'
  | 'recipients'
  | 'enableSigningOrder'
  | 'pricingTables'
  | 'groups'
  | 'enableDirectPayment'
  | 'enableAutoSendInvoice'
> & {
  _id: string
  updatedBy: {
    name: string
    email: string
  }
  timezone: {
    abbreviation: string
    zone: TimeZoneEnum
  }
  businessName: string
}
export interface ccRecipient {
  _id?: string
  id: string
  email: string
  imageUrl: string
  contactName: string
  firstName: string
  lastName: string
}
export interface BuilderAppStore {
  email: string
  userId: string
  apiKey: string
  tokenId: string
  companyId: string
  timeZone: TimeZoneEnum
  userName: string
  parentBaseUrl: string
  handshake: Promise<any>
  requestRetryCount: number
  document: IDocument
  unsavedChanges: boolean
  sendAttempted: boolean
  currency: string
  locale: string
  locationStaticCustomValues: Record<string, any>[]
  locationCustomValues: Record<string, any>[]
  locationCustomFields: Record<string, any>[]
  userData: any
  linkReference: Record<string, any>[]
  whiteLabelViewerBaseUrl: string
  invoiceWhiteLabelBaseUrl: string
  isInternal: boolean
}

export interface TemplateBuilderAppStore extends BuilderAppStore {
  document: IDocumentTemplate
  docFormPublicLink: string
}

export interface Config<T extends string> {
  mode: T
  PROPOSAL_SERVICE_URL: string
  MEDIA_CENTER_URL?: string
  CONTACT_SERVICE_URL: string
  PRODUCT_SERVICE_URL: string
  DOCUMENT_VIEWER_URL: string
  TAX_SERVICE_URL: string
  PUBLIC_API_URL: string
  USER_SERVICE_URL: string
  CUSTOM_DATA_SERVICE_URL: string
  PAYMENTS_SERVICE_URL: string
  INVOICE_SERVICE_URL: string
  EMAIL_BUILDER_SERVICE_URL: string
}

export type Envs = 'development' | 'staging' | 'beta' | 'production'
export type EnvConfig<X extends string, Y> = {
  [key in X]: Y
}

export type EnvConfigType = EnvConfig<'development', Config<'dev'>> &
  EnvConfig<'staging', Config<'staging'>> &
  EnvConfig<'beta', Config<'beta'>> &
  EnvConfig<'production', Config<'production'>>

export type DocumentViewerConfig<T extends string> = Pick<
  Config<T>,
  'mode' | 'PROPOSAL_SERVICE_URL' | 'INVOICE_SERVICE_URL'
>

export type DocumentViewerConfigType = EnvConfig<
  'development',
  DocumentViewerConfig<'dev'>
> &
  EnvConfig<'staging', DocumentViewerConfig<'staging'>> &
  EnvConfig<'staging-version', DocumentViewerConfig<'staging'>> &
  EnvConfig<'beta', DocumentViewerConfig<'beta'>> &
  EnvConfig<'production', DocumentViewerConfig<'production'>>

export interface ProposalPayment {
  _id: string
  documentRevision: number
  documentId: string
  locationId: string
  invoiceId: string
  scheduleId: string
  deleted: boolean
  invoiceType: PriceTypeEnum
  paymentSettings: PaymentSettings
  updatedBy: string
  generateInvoiceOnSigning?: boolean
  paymentDepositSettings?: PaymentSchedule
  enableAutoPayment?: boolean
}

export interface RrulePayment {
  rrule?: ScheduleRRuleOptions
}
export interface PaymentSettings {
  schedule?: RrulePayment
}

export interface InitPDFProcessPayload {
  documentId: string
  locationId: string
  forTemplate?: boolean
  files: {
    url: string
    storagePath: string
    order: number
    type: string
  }[]
}
