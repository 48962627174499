<script setup lang="ts">
import {
  IElement,
  ELEMENTS_LOOKUP,
  SignatureElementOptions,
  ProductListElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import { PropType, ref, watch } from 'vue'
import CProductList from '../elements/ProductList/CProductList.vue'
import CTable from '../elements/CTable.vue'
import CText from '../elements/CText.vue'
import CVideo from '../elements/CVideo.vue'
import CImage from '../elements/CImage.vue'
import CSignature from '../elements/CSignature.vue'
import FloatingElementRenderer from './FloatingElementRenderer.vue'

const props = defineProps({
  element: {
    required: true,
    type: Object as PropType<IElement<any>>,
  },
  pageId: {
    required: true,
    type: String,
  },
  page: {
    required: true,
    type: Object as PropType<IElement>,
  },
  pageRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
})
const parentRef = ref<HTMLElement>()
const localPageRef = ref<HTMLElement | undefined>(props.pageRef)

watch(
  () => props.pageRef,
  newVal => {
    localPageRef.value = newVal
  },
  { immediate: true }
)

const getDraggableElement = (children: IElement[]) => {
  if (!children) return []
  return children.filter(element => element && element?.component?.isDraggable)
}
</script>

<template>
  <div class="relative clearfix" style="width: 100%">
    <section
      style="transform: translateZ(0); width: inherit"
      class="flex relative print:block;"
      v-if="!element.component.isDraggable"
      ref="parentRef"
    >
      <FloatingElementRenderer
        :elements="getDraggableElement(element?.children)"
        :pageId="pageId"
        :page="page"
        :pageRef="pageRef"
        :parentRef="parentRef"
      />

      <CText
        :element="element"
        :pageId="pageId"
        v-if="element.type === ELEMENTS_LOOKUP.TEXT"
      />
      <CImage
        :element="element"
        :pageId="pageId"
        v-if="element.type === ELEMENTS_LOOKUP.IMAGE"
      />
      <CVideo
        :element="element"
        :pageId="pageId"
        v-if="element.type === ELEMENTS_LOOKUP.VIDEO"
      />
      <CTable
        :element="element"
        :pageId="pageId"
        v-if="element.type === ELEMENTS_LOOKUP.TABLE"
      />
      <CSignature
        :element="(element as IElement<SignatureElementOptions>)"
        :pageId="pageId"
        v-if="element.type === ELEMENTS_LOOKUP.SIGNATURE"
      />
      <CProductList
        :element="(element as IElement<ProductListElementOptions>)"
        :pageId="pageId"
        v-if="element.type === ELEMENTS_LOOKUP.PRODUCT_LIST"
      />
    </section>
  </div>
</template>
