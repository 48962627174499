<script setup lang="ts">
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useLineItems } from '@/composable/useLineItems'
import {
  UITextMdMedium,
  UITextSmMedium,
  UITextSmRegular,
  UIDivider,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import {
  DynamicTable,
  LineItem,
  ProductListElementOptions,
  getCurrencyUtil,
  CurrencyConfigs,
  PriceTypeEnum,
  PaymentScheduleSummary,
} from '@gohighlevel/ghl-proposals-common'
import { PropType, computed } from 'vue'
import { IElement, DiscountType } from '@gohighlevel/ghl-proposals-common'
import { getProductListColumns } from './ProductListColumns'
import { useDocumentStore } from '@/store/document'
import { useDocument } from '@/composable'

const store = useDocumentStore()
const { isPreview } = useDocument()
const isCCrecipient = computed(() => store.isCcRecipient)
const props = defineProps({
  element: {
    type: Object as PropType<IElement<ProductListElementOptions>>,
    required: true,
  },
})
const primaryRecipient = computed(() => {
  return store.recipients.find(({ isPrimary }) => isPrimary === true)
})
const isPrimaryRecipient = computed(() => {
  return (
    primaryRecipient.value &&
    primaryRecipient.value?.id === store.activeRecipient?.id
  )
})
const isEditable = computed(() => {
  if (isPreview) {
    return true
  } else {
    return (
      isPrimaryRecipient.value &&
      !primaryRecipient?.value?.hasCompleted &&
      store.hasPrevRecipientsCompleted &&
      store.isEditable &&
      isCCrecipient.value === false
    )
  }
})
const discountType = computed(
  () => props.element.component.options.discountType
)
const discountValue = computed(
  () => props.element.component.options.discountValue
)

const onUpdate = (key: keyof LineItem, value: any, row: any) => {
  store.updateLineItems(props.element?.id, row.key, key, value)
}
const onSelectionChange = (row: LineItem, value: boolean) => {
  store.updateLineItems(props.element?.id, row.key, 'selected', value)
}
const allLineItems = computed(() => props.element.component.options.lineItems)
const currency = computed(() => {
  return allLineItems.value && allLineItems.value.length > 0
    ? allLineItems.value[0].currency
    : (props.element.component.options.currencyCode as string)
})
const columns = getProductListColumns(
  currency.value as string,
  props.element.version,
  onUpdate
)
const { formatCurrency, getCurrencySymbol, getCurrency } = getCurrencyUtil(
  props.element.component.options.currencyCode as string,
  CurrencyConfigs
)
const { subTotal, amountDue, totalDiscount, appliedTax } = useLineItems(
  props.element
)
const withCheckBox = computed(() => {
  return props.element.version > 1
})

const styles = computed(() => {
  return props.element?.responsiveStyles.large
})
const customStyleForImageParent = computed(() => {
  return {
    backgroundColor: styles.value?.backgroundColor,
    marginTop: styles.value?.marginTop,
    marginBottom: styles.value?.marginBottom,
    marginLeft: styles.value?.marginLeft,
    marginRight: styles.value?.marginRight,
    paddingTop: styles.value?.paddingTop,
    paddingBottom: styles.value?.paddingBottom,
    paddingLeft: styles.value?.paddingLeft,
    paddingRight: styles.value?.paddingRight,
    minWidth: '400px',
  }
})
const lineItems = computed(() => {
  return props.element?.component?.options?.lineItems?.map(item => {
    return {
      ...item,
      disabled: item.optional && isEditable.value ? false : true,
      qtyEditable:
        item.qtyEditable && isEditable.value ? item.qtyEditable : false,
    }
  })
})

const showPaymentDepositSchedule = computed(() => {
  const allProductList = store.getAllProductList()
  if (allProductList.length > 0) {
    const lastProductList = allProductList[allProductList.length - 1]
    return (
      lastProductList.id === props.element.id &&
      store.paymentInfo &&
      store.paymentInfo.paymentDepositSettings &&
      Object.keys(store.paymentInfo.paymentDepositSettings).length > 0
    )
  } else {
    return false
  }
})

const enablePaymentDeposit = computed(() => {
  return (
    store?.paymentInfo.invoiceType === PriceTypeEnum.ONETIME &&
    store?.document?.grandTotal?.amount > 0
  )
})

const paymentDepositScheduleInfoCount = computed(() => {
  const allProductList = store.getAllProductList()
  const length = allProductList.length
  return Array.from({ length }, (_, i) => i + 1).join(',')
})

const productCurrencySymbol = computed(() =>
  getCurrencySymbol(currency.value || getCurrency())
)
</script>

<template>
  <div
    class="w-full flex flex-col print:break-inside-auto break-inside-auto overflow-auto"
  >
    <div
      :id="props.element?.id"
      class="products-pricing-table p-4 border rounded-md pricing-table"
      :style="customStyleForImageParent"
    >
      <DynamicTable
        :config="columns"
        :uniqueKey="'key'"
        :data="lineItems"
        :selectable="isEditable"
        :with-check-box="withCheckBox"
        @on-selection-change="onSelectionChange"
      />
      <div class="mx-4 mt-4">
        <div class="w-full h-[48.398px]"></div>
      </div>
      <div
        :class="`
           ${showPaymentDepositSchedule ? 'min-w-[50%]' : 'min-w-[33.333%]'}
        `"
        class="flex alignment-right float-right flex-col"
      >
        <div class="flex flex-col border-t mt-9 pt-6 border-b pb-6 mb-4">
          <div class="flex text-neutral-900">
            <UITextSmMedium class="py-2 flex-1 flex flex-col">
              {{ $t('documentViewer.subtotal') }}
            </UITextSmMedium>
            <UITextSmRegular class="p-2 flex-1 flex flex-col text-right">
              {{ formatCurrency(subTotal) }}
            </UITextSmRegular>
          </div>
          <div v-if="discountValue > 0" class="flex">
            <UITextSmMedium class="py-2 flex-1 flex flex-col">
              {{ $t('documentViewer.discount') }}
              {{
                discountType === DiscountType.PERCENTAGE
                  ? `(${discountValue}%)`
                  : ''
              }}
            </UITextSmMedium>
            <UITextSmRegular class="p-2 flex-1 flex flex-col text-right">
              -{{ formatCurrency(totalDiscount) }}
            </UITextSmRegular>
          </div>
          <div v-else style="height: 36px"></div>
        </div>
        <div class="flex" v-for="tax in appliedTax" v-bind:key="tax._id">
          <UITextSmRegular class="py-2 flex-1 flex flex-col">
            {{ tax.name }}
          </UITextSmRegular>
          <UITextSmRegular class="p-2 flex-1 flex flex-col text-right">
            {{ formatCurrency(tax.tax) }}
          </UITextSmRegular>
        </div>
        <div class="flex text-neutral-900">
          <UITextMdMedium class="py-2 flex-1 flex flex-col">
            {{ $t('documentViewer.amountDue') }}
          </UITextMdMedium>
          <UITextMdMedium class="p-2 text-right flex-1 flex flex-col">
            {{ formatCurrency(amountDue) }}
          </UITextMdMedium>
        </div>

        <div
          v-if="showPaymentDepositSchedule && enablePaymentDeposit"
          class="pr-2 pt-2"
        >
          <UIDivider
            :titlePlacement="'center'"
            :dashed="false"
            :vertical="false"
          />
          <div class="flex items-center py-2">
            <UITextSmMedium class="pr-2">
              {{ $t('paymentSchedule.label') }}
            </UITextSmMedium>
            <UITooltip :placement="'top'">
              <template #trigger>
                <InfoCircleIcon class="w-4 h-4 text-gray-500" />
              </template>
              {{
                $t('paymentSchedule.paymentDepositScheduleInfo', {
                  count: paymentDepositScheduleInfoCount,
                })
              }}
            </UITooltip>
          </div>

          <PaymentScheduleSummary
            class="pt-2"
            :paymentScheduleInfo="store.paymentInfo.paymentDepositSettings"
            :currencyCode="currency"
            :total="store.document?.grandTotal?.amount"
            :isOuterBorder="false"
            :themeType="'horizontal'"
          />
          <div class="flex items-center pt-2">
            <UITextSmMedium class="py-2 flex-1 flex flex-col">
              {{ $t('paymentSchedule.totalAmountDue') }}
            </UITextSmMedium>
            <UITextSmRegular class="flex-1 flex flex-col text-right font-bold">
              {{
                formatCurrency(
                  store.document?.grandTotal?.amount,
                  productCurrencySymbol
                )
              }}
            </UITextSmRegular>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.products-pricing-table {
  table {
    thead {
      th {
        border-bottom: 2px solid var(--primary-600);
      }
    }
  }
}
.editable-table-input-form-item {
  .hl-input-text {
    .n-input-wrapper {
      .n-input__suffix {
        display: none !important;
      }
    }
  }
}
</style>
