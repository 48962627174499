<script setup lang="ts">
import { UITextMdNormal, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { PencilLineIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useDocumentStore } from '@/store/document'
import { PropType, computed, inject, onMounted, ref, toRef, watch } from 'vue'
import {
  Tooltip,
  IElement,
  IRecipient,
  InitialsElementOptions,
  getSignatureElementColorStyles,
  PageElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import SignatureModal from '../modals/SignatureModal.vue'
import {
  useDocument,
  useFillableFields,
  useInitials,
  usePrintAdjust,
  useTypography,
} from '@/composable'

const props = defineProps({
  page: {
    type: Object as PropType<IElement<PageElementOptions>>,
    required: true,
  },
  element: {
    type: Object as PropType<IElement<InitialsElementOptions>>,
    required: true,
  },
  pageId: {
    type: String,
    required: true,
  },
  pageRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  parentRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
})

const { signatureText } = useInitials()
const localInitialsText = ref('')
const localParentRef = ref<HTMLElement | undefined>(props.parentRef)
const localPageRef = ref<HTMLElement | undefined>(props.pageRef)
watch(
  () => props.parentRef,
  newVal => {
    localParentRef.value = newVal
  },
  { immediate: true }
)
watch(
  () => props.pageRef,
  newVal => {
    localPageRef.value = newVal
  },
  { immediate: true }
)
const { position: printPosition, elRef } = usePrintAdjust(
  props.page,
  props.element,
  localPageRef,
  localParentRef
)
const showSignatureModal = ref(false)
const { toCapitalize } = useTypography()
const isPDFView = inject<boolean>('isPDFView')
const { isPreview } = useDocument()
const store = useDocumentStore()
const isCCrecipient = computed(() => store.isCcRecipient)
const isEditing = computed(() => store.isEditing)
const activeRecipient = computed(() => store.activeRecipient)
const { setActiveField, activeElement } = useFillableFields()

const isActiveSignatureElemForRecipient = computed(
  () =>
    store.activeRecipient?.id === props.element?.component.options.recipient &&
    store.activeRecipient?.entityName ===
      props.element?.component.options.entityName
)

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id, entityName }) =>
        id === props.element?.component.options.recipient &&
        entityName === props.element?.component.options.entityName
    ) || ({} as IRecipient)
  )
})

const isRecipientHasCompleted = computed(() => {
  return (
    activeRecipient.value &&
    activeRecipient.value?.hasCompleted &&
    isActiveSignatureElemForRecipient.value
  )
})

const shouldEnableElement = computed(
  () =>
    !(
      isActiveSignatureElemForRecipient.value &&
      !isElementSigned.value &&
      store.hasPrevRecipientsCompleted &&
      isCCrecipient.value === false
    )
)

const isAlreadyAccepted = computed(() => store.isAlreadyAccepted)
const isElementSigned = computed(
  () =>
    isPDFView ||
    props.element?.component?.options?.isSigned ||
    recipient.value.hasCompleted
)

const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return toCapitalize(firstName ? `${firstName} ${lastName || ''}` : '')
})

const initials = computed(() => {
  const { firstName, lastName, email } = recipient.value
  if (firstName && lastName) {
    return `${toCapitalize(firstName.trim().slice(0, 1))}.${toCapitalize(
      lastName.trim().slice(0, 1)
    )}.`
  }
  if (firstName) {
    return toCapitalize(firstName).slice(0, 2)
  }
  if (lastName) {
    return toCapitalize(lastName).slice(0, 2)
  }
  if (email) {
    return `${email.charAt(0).toUpperCase()}.${email.charAt(1).toUpperCase()}.`
  }
  return ''
})

watch(
  [initials, signatureText],
  ([updatedInitials, updatedText]) => {
    localInitialsText.value = updatedText || updatedInitials
  },
  { immediate: true }
)

const date = toRef(recipient.value.signedDate)
const customClassesForImg = computed(() => {
  const styles = props.element?.responsiveStyles.large
  const classArr = [styles?.align]
  return classArr
})
const dimensions = computed(
  () =>
    props.element?.responsiveStyles.large.dimensions || {
      height: 68,
      width: 190,
    }
)
const ghostStyles = computed(() => {
  if (props.element?.component.options?.isGhost) {
    const styleMap = props.element?.responsiveStyles.large as any
    const position = styleMap.position
    const scale = styleMap.scale

    const stylesArray = []
    if (position) {
      let styleStr = position?.preferBottom
        ? `bottom: ${position.bottom}px;`
        : `top: ${position.top}px; `
      styleStr += position?.preferRight
        ? `right: ${position.right}px;`
        : `left: ${position.left}px;`
      styleStr += 'z-index: 100;'

      stylesArray.push(styleStr)
    }
    if (scale) {
      stylesArray.push(
        `transform: scale(${scale.scaleX}, ${scale.scaleY}) translateZ(0);`
      )
    }
    if (dimensions.value) {
      stylesArray.push(
        `height: ${dimensions.value.height}px; width: ${dimensions.value.width}px;`
      )
    }
    stylesArray.push('position: absolute; display: flex;')
    return stylesArray.join(' ').trim()
  }
  return ''
})

const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
    ...(styles?.transform ? { transform: styles.transform } : {}),
  }
})

onMounted(() => {
  if (store.isAlreadyAccepted) {
    store.updateSignatureElement(props.element?.id, props.pageId, true)
  }
})
const onUpdateSignature = (dataImg: string) => {
  const timestamp = new Date()
  date.value = timestamp.toISOString()
  store.updateSignatureForRecipient(
    recipient.value.id,
    dataImg,
    date.value,
    true
  )
  store.updateSignatureElement(props.element?.id, props.pageId, true)
}
const onUpdateSignatureStr = (text: string) => {
  store.updateSignatureElementAltText(props.element?.id, props.pageId, text)
}
const shouldShowSignatureModal = computed(
  () =>
    !isPreview &&
    !isAlreadyAccepted.value &&
    (!recipient.value.initialsImgUrl || isElementSigned.value) &&
    recipient.value.id === activeRecipient?.value?.id &&
    recipient.value.entityName === activeRecipient?.value?.entityName &&
    !isRecipientHasCompleted.value
)
const onClick = () => {
  if (
    !isEditing.value &&
    isActiveSignatureElemForRecipient.value &&
    !activeRecipient.value?.hasCompleted &&
    isCCrecipient.value === false
  ) {
    store.setIsEditing(true)
  }
  if (shouldShowSignatureModal.value) {
    showSignatureModal.value = true
    setActiveField(props.element?.id as string)
  } else {
    if (isActiveSignatureElemForRecipient.value) {
      store.updateSignatureElement(props.element?.id, props.pageId, true)
      setActiveField(props.element?.id as string)
    }
  }
}
const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const colorStyles = computed(() => {
  const dimensionsObj = dimensions.value
  const commonStyles = {
    ...(dimensionsObj
      ? {
          height: `${dimensionsObj.height}px`,
          width: `${dimensionsObj.width}px`,
        }
      : {}),
  }
  if (isElementSigned.value) {
    return { ...commonStyles } as {
      color: string
      backgroundColor: string
      borderColor: string
    }
  }
  return { ...commonStyles, ...styles.value }
})
</script>

<template>
  <div
    ref="elRef"
    class="signature-el"
    :class="{ filled: isElementSigned }"
    :style="ghostStyles"
    :id="props.element?.id"
    :data-attr="props.element?.component.options.altText"
  >
    <span class="option--required">*</span>
    <SignatureModal
      :forInitials="true"
      :alignToCenter="true"
      :signerName="localInitialsText"
      v-model:show="showSignatureModal"
      @update:signatureText="onUpdateSignature"
      @update:signatureTextStr="onUpdateSignatureStr"
    />
    <Tooltip direction="bottom" :enable="true">
      <template #trigger>
        <div
          class="print:break-inside-avoid !break-inside-avoid box-border"
          :class="[customClassesForImg]"
        >
          <div
            :style="customStyleForImageParent"
            :class="{
              'cursor-pointer':
                isActiveSignatureElemForRecipient &&
                !isAlreadyAccepted &&
                isCCrecipient === false,
              'text-disabled cursor-not-allowed':
                (!isPDFView &&
                  !isActiveSignatureElemForRecipient &&
                  !isElementSigned) ||
                isCCrecipient === true,
            }"
          >
            <div
              @click="onClick"
              class="flex justify-center text-[14px] items-center whitespace-pre-line"
              :class="`text-[${colorStyles.color}]  
                        ${
                          !isElementSigned
                            ? `border rounded-sm ${
                                isPDFView || shouldEnableElement
                                  ? 'border-disabled  bg-disabled'
                                  : `border-[${colorStyles.borderColor}]  bg-[${colorStyles.backgroundColor}]`
                              }`
                            : 'rounded-sm'
                        }
                `"
              :style="{
                ...colorStyles,
                'border-width':
                  activeElement && activeElement.id === props.element?.id
                    ? '0px'
                    : '1px',
              }"
            >
              <div
                v-if="recipient.initialsImgUrl && isElementSigned"
                class="flex h-full max-w-full"
                style="flex: 1 0 auto"
              >
                <img
                  crossorigin="anonymous"
                  style="height: 100%; max-width: 100%; margin: auto"
                  :src="recipient.initialsImgUrl"
                />
              </div>
              <div
                v-else
                class="flex items-center overflow-hidden"
                :class="`${
                  shouldEnableElement
                    ? 'text-disabled'
                    : `text-[${colorStyles.color}]`
                } `"
              >
                <PencilLineIcon
                  class="h-5 w-5 mr-2"
                  :class="{
                    'stroke-disabled': shouldEnableElement,
                  }"
                />
                <UITextMdNormal
                  :style="{
                    'text-overflow': 'ellipsis',
                    'white-space': 'nowrap',
                    overflow: 'hidden',
                  }"
                  >{{ props.element?.component.options.text }}</UITextMdNormal
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <UITextSmRegular
          class="capitalize font-semibold px-2 py-1 text-xs"
          :style="{ color: styles.placeholderColor }"
          >{{ recipientName }}</UITextSmRegular
        >
      </template>
    </Tooltip>
  </div>
</template>

<style scoped lang="scss">
.signature-el {
  position: relative;
  .recipient-name {
    position: absolute;
    top: -25px;
    left: 0;
  }
}
@media print {
  .signature-el {
    break-inside: avoid-page !important;
    // top: v-bind('topPosition') !important;
    transform: v-bind('printPosition.top') !important;
  }
}
</style>
