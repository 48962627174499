<script setup lang="ts">
import { PropType, ref, watch, computed } from 'vue'
import {
  ModalEvents,
  PaymentSchedule,
  PaymentScheduleType,
  PaymentDueOn,
  BillingScheduleType,
  getPaymentScheduleRegularUnits,
  PaymentScheduleRegularUnits,
} from '../../../types'
import { CustomModal } from '../CustomModal'
import {
  UIForm,
  UITextSmMedium,
  UIRadioGroupItem,
  UIRadioGroup,
  UISpace,
  UIDatepicker,
  UIFormItem,
  UIInputNumber,
  UIButton,
  UITextMdMedium,
  UITextSmRegular,
  UIRadio,
  UISelect,
  UIInput,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { cloneDeep } from 'lodash-es'
import {
  Trash02Icon,
  Lightbulb02Icon,
  InfoCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import dayjs from 'dayjs'
import currency from 'currency.js'
import { getCurrencyUtil, getDatesBasedOnInterval } from '../../../utils'
import { useI18n } from 'vue-i18n'
import { useInvoice } from '../../../composition'
import {
  formatDate,
  addDaysToDate,
  dayjsTZ,
  isDateOnlyAfter,
  isDateInPastUTC,
} from '../../../utils/date'

const formRef = ref(null)
const { t } = useI18n()
const emits = defineEmits([ModalEvents.ON_SUCCESS, ModalEvents.ON_CANCEL])
const { getInvoiceSettings, invoiceSettings } = useInvoice()

const props = defineProps({
  paymentScheduleInfo: {
    type: Object as PropType<PaymentSchedule> | null,
  },
  show: {
    type: Boolean,
    default: false,
  },
  currencyCode: {
    type: String,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  isInternal: {
    type: Boolean,
    default: false,
  },
  locationId: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const defaultPaymentSchedule = {
  dueDate: undefined,
  paymentDueOn: PaymentDueOn.UPON_DOCUMENT_COMPLETE,
  billingScheduleType: BillingScheduleType.REGULAR,
  billingSchedule: {
    interval: 1,
    unit: PaymentScheduleRegularUnits.MONTHS,
  },
  type: PaymentScheduleType.PERCENTAGE,
  schedules: [
    { value: 0, dueDate: undefined },
    { value: 0, dueDate: undefined },
  ],
}
const paymentScheduleForm = ref<PaymentSchedule>(defaultPaymentSchedule)
const setPaymentScheduleModal = () => {
  if (
    props.paymentScheduleInfo &&
    Object.keys(props.paymentScheduleInfo).length > 0
  ) {
    paymentScheduleForm.value = cloneDeep(props.paymentScheduleInfo)
  } else {
    paymentScheduleForm.value = cloneDeep(defaultPaymentSchedule)
  }
}

const fetchDueDate = async () => {
  if (paymentScheduleForm.value.paymentDueOn === PaymentDueOn.CUSTOM_DATE) {
    if (!paymentScheduleForm.value?.dueDate) {
      const date = new Date()
      await getInvoiceSettings(props.locationId)
      const dueDate =
        invoiceSettings.value?.dueAfterXDays === 0
          ? formatDate(date, 'YYYY-MM-DD')
          : addDaysToDate(
              formatDate(date, 'YYYY-MM-DD'),
              invoiceSettings.value?.dueAfterXDays || 14
            )

      const schedules = paymentScheduleForm.value?.schedules || []
      if (schedules.length > 0) {
        const lastScheduleDueDate = schedules[schedules.length - 1].dueDate
        if (lastScheduleDueDate) {
          if (
            dayjs(dayjsTZ(lastScheduleDueDate, 'YYYY-MM-DD')).isAfter(
              dayjs(dayjsTZ(dueDate, 'YYYY-MM-DD'))
            )
          ) {
            paymentScheduleForm.value.dueDate = lastScheduleDueDate
          } else {
            paymentScheduleForm.value.dueDate = dueDate
          }
        }
      }
      if (!paymentScheduleForm.value.dueDate) {
        paymentScheduleForm.value.dueDate = dueDate
      }
    }
  }
}

const updateInvoiceDueDate = () => {
  const schedules = paymentScheduleForm.value?.schedules || []
  if (schedules.length > 0) {
    const lastScheduleDueDate = schedules[schedules.length - 1].dueDate
    if (lastScheduleDueDate) {
      paymentScheduleForm.value.dueDate = lastScheduleDueDate
    }
  }
}

watch(
  () => [props.show],
  ([_newShowValue]) => {
    if (_newShowValue) {
      setPaymentScheduleModal()
      fetchDueDate()
    }
  },
  { immediate: true }
)

const deletePaymentSchedule = idx => {
  paymentScheduleForm.value.schedules.splice(idx, 1)
}

const onUpdateScheduleValue = (schedule, value) => {
  const [, decimal] = String(value).split('.')
  if (!decimal || decimal.length <= 2) {
    schedule.value = value
  }
}

const addPaymentSchedule = () => {
  paymentScheduleForm.value.schedules.push({ value: 0, dueDate: undefined })
  setSchedulesBasedOnInterval()
}

const totalScheduleValue = computed(() => {
  const { formatCurrency } = getCurrencyUtil(props.currencyCode)
  if (paymentScheduleForm.value.type === PaymentScheduleType.PERCENTAGE) {
    const total = paymentScheduleForm.value.schedules?.reduce(
      (acc, schedule) => acc + schedule.value,
      0
    )
    return { value: total, text: `${total}%` }
  } else {
    const total = paymentScheduleForm.value.schedules?.reduce(
      (acc, schedule) => currency(schedule.value).add(acc),
      currency(0)
    )
    return {
      value: total?.value || 0,
      text: `${formatCurrency(total?.value || 0, props.currencyCode)}`,
    }
  }
})

const remainingValue = computed(() => {
  const { formatCurrency } = getCurrencyUtil(props.currencyCode)
  const { value } = totalScheduleValue.value
  if (paymentScheduleForm.value.type === PaymentScheduleType.PERCENTAGE) {
    const remaining = 100 - value
    return { value: remaining, text: `${remaining}%` }
  } else {
    const remaining = currency(props.total).subtract(value).value
    return {
      value: remaining,
      text: `${formatCurrency(remaining, props.currencyCode)}`,
    }
  }
})

const paymentDueOnOptions = [
  {
    value: PaymentDueOn.UPON_DOCUMENT_COMPLETE,
    label: t('paymentSchedule.uponPrimarySignature'),
  },
  { value: PaymentDueOn.CUSTOM_DATE, label: t('paymentSchedule.customDate') },
]

const billingScheduleOptions = [
  {
    value: BillingScheduleType.REGULAR,
    label: t('paymentSchedule.regular'),
  },
  {
    value: BillingScheduleType.CUSTOM_DATE,
    label: t('paymentSchedule.customDate'),
  },
]

const intervalTypes = computed(() => {
  const isPlural =
    paymentScheduleForm.value.billingSchedule?.interval > 1 ? true : false
  const units = getPaymentScheduleRegularUnits(isPlural)
  return [...units.REGULAR_UNITS]
})

const handlePaymentDueOnChange = (event: any) => {
  const value = event.target.value
  paymentScheduleForm.value.paymentDueOn = value
  if (value === PaymentDueOn.UPON_DOCUMENT_COMPLETE) {
    if (
      paymentScheduleForm.value.schedules.length > 0 &&
      paymentScheduleForm.value.schedules[0].dueDate
    ) {
      for (let i = 0; i < paymentScheduleForm.value.schedules.length; i++) {
        paymentScheduleForm.value.schedules[i].dueDate = undefined
      }
    }
    paymentScheduleForm.value.dueDate = undefined
  }
  setSchedulesBasedOnInterval()
  fetchDueDate()
}

const handleBillingScheduleChange = (event: any) => {
  const value = event.target.value
  paymentScheduleForm.value.billingScheduleType = value
  setSchedulesBasedOnInterval()
}

const onCancelForms = () => {
  emits(ModalEvents.ON_CANCEL)
}

const onUpdateInterval = (value: number) => {
  if (value) {
    paymentScheduleForm.value.billingSchedule.interval = value
  }
  setSchedulesBasedOnInterval()
}

const onUpdateRegularUnit = (value: string) => {
  if (value) {
    paymentScheduleForm.value.billingSchedule.unit = value
  }
  setSchedulesBasedOnInterval()
}

const formatTime = (schedule, dateFormat = 'MMM DD, YYYY') => {
  if (schedule && schedule.dueDate) {
    return dayjs(schedule.dueDate).format(dateFormat)
  } else {
    return ''
  }
}

const setSchedulesBasedOnInterval = () => {
  if (
    paymentScheduleForm.value.paymentDueOn === PaymentDueOn.CUSTOM_DATE &&
    paymentScheduleForm.value.billingScheduleType ===
      BillingScheduleType.REGULAR &&
    paymentScheduleForm.value.billingSchedule.interval &&
    paymentScheduleForm.value.billingSchedule.unit &&
    paymentScheduleForm.value.schedules.length > 0 &&
    paymentScheduleForm.value.schedules[0].dueDate
  ) {
    const schedules = getDatesBasedOnInterval(
      paymentScheduleForm.value?.schedules,
      paymentScheduleForm.value?.billingSchedule
    )
    paymentScheduleForm.value.schedules = schedules
    if (schedules && schedules?.length > 0) {
      paymentScheduleForm.value.dueDate =
        schedules[schedules.length - 1].dueDate
    }
  }
}

const onChangeDueDate = (schedule, event) => {
  schedule.dueDate = dayjs(new Date(event)).format('YYYY-MM-DD')
  setSchedulesBasedOnInterval()
  updateInvoiceDueDate()
}

const dueDateTextUponCompletion = () => {
  const isPlural =
    paymentScheduleForm.value.billingSchedule?.interval > 1 ? true : false
  const label =
    getPaymentScheduleRegularUnits(isPlural)?.REGULAR_UNITS?.find(
      unit => unit.value === paymentScheduleForm.value.billingSchedule?.unit
    )?.label || ''
  return `+ ${
    paymentScheduleForm.value.billingSchedule?.interval || ''
  } ${label}`
}

const formRules = {
  valueRule: {
    validator(_, value) {
      if (value <= 0)
        return new Error(t('validations.min.paymentScheduleValue'))
      return true
    },
  },
  dueDateRule: {
    validator(rule, value) {
      if (paymentScheduleForm.value.paymentDueOn === PaymentDueOn.CUSTOM_DATE) {
        if (!value) {
          return new Error(t('validations.required.paymentScheduleDueDate'))
        }
      }
      const idx = Number(rule.field.split('.')[1])
      if (
        paymentScheduleForm.value.paymentDueOn ===
          PaymentDueOn.UPON_DOCUMENT_COMPLETE &&
        paymentScheduleForm.value.billingScheduleType ===
          BillingScheduleType.CUSTOM_DATE
      ) {
        if (idx > 0 && !value) {
          return new Error(t('validations.required.paymentScheduleDueDate'))
        }
      }
      if (idx > 0) {
        if (
          isDateOnlyAfter(
            paymentScheduleForm.value.schedules[idx - 1].dueDate,
            value
          )
        )
          return new Error(
            t('validations.invalid.paymentScheduleDueDateOrdered')
          )
      }

      if (paymentScheduleForm.value.dueDate) {
        if (
          isDateOnlyAfter(
            value,
            paymentScheduleForm.value.dueDate,
            'YYYY-MM-DD',
            true
          )
        )
          return new Error(
            t('validations.invalid.paymentScheduleDueDateLtInvoiceDue')
          )
      }

      if (isDateInPastUTC(paymentScheduleForm.value.dueDate))
        return new Error(t('validations.invalid.paymentScheduleDueDatePast'))

      return true
    },
  },
  invoiceTotalRule: {
    validator() {
      if (remainingValue.value.value !== 0) {
        if (paymentScheduleForm.value.type === PaymentScheduleType.PERCENTAGE)
          return new Error(t('validations.invalid.paymentScheduleTotalPercent'))
        else
          return new Error(t('validations.invalid.paymentScheduleTotalValue'))
      }
    },
  },
}

const addPaymentPlan = async () => {
  try {
    await formRef?.value?.getForm().validate()
    emits(ModalEvents.ON_SUCCESS, paymentScheduleForm.value)
  } catch (error) {
    console.error(error)
  }
}

const getCurrencySymbol = (currencyCode: string) => {
  const { getCurrencySymbol } = getCurrencyUtil(currencyCode)
  return getCurrencySymbol()
}

const isDueDateCustomDisabled = computed(() => {
  if (
    paymentScheduleForm.value.paymentDueOn === PaymentDueOn.CUSTOM_DATE &&
    paymentScheduleForm.value.billingScheduleType ===
      BillingScheduleType.REGULAR &&
    paymentScheduleForm.value.schedules.length > 0 &&
    (paymentScheduleForm.value.schedules[0].dueDate === undefined ||
      !paymentScheduleForm.value.schedules[0].dueDate)
  ) {
    return true
  } else {
    return false
  }
})

const enableToolTipForDueDate = (idx: number) => {
  if (
    idx === 0 &&
    paymentScheduleForm.value.paymentDueOn === PaymentDueOn.CUSTOM_DATE
  ) {
    return true
  }
  if (
    idx > 0 &&
    paymentScheduleForm.value.billingScheduleType ===
      BillingScheduleType.CUSTOM_DATE
  ) {
    return true
  }
  return false
}
</script>

<template>
  <CustomModal
    :width="950"
    :show="props.show"
    :modalType="'primary'"
    :title="$t('paymentSchedule.addPaymentSchedule')"
    @on-cancel="onCancelForms"
    @on-success="addPaymentPlan"
    id="payment-schedule-modal"
    :cancelActionText="''"
    :successActionText="''"
    class="payment-schedule-modal"
    :headerIcon="false"
    :disabled="disabled"
  >
    <div class="flex">
      <div class="flex w-6/12 p-4">
        <UIForm
          id="payment-plan-form"
          ref="formRef"
          :model="paymentScheduleForm"
          :rules="formRules"
          show-label
          class="w-full"
        >
          <div class="flex items-center payment-type">
            <UIRadioGroup
              v-model:value="paymentScheduleForm.type"
              @update:value="
                paymentScheduleForm.schedules.forEach(
                  schedule => (schedule.value = 0)
                )
              "
              name="paymentScheduleType"
              class="mb-8 w-full"
            >
              <UISpace :justify="'space-between'" :size="'medium'">
                <UIRadioGroupItem :value="PaymentScheduleType.PERCENTAGE">
                  <template #content>
                    <UITextSmMedium class="pr-20 payment-due-on">{{
                      $t('paymentSchedule.percentageSchedule')
                    }}</UITextSmMedium>
                  </template>
                </UIRadioGroupItem>
                <UIRadioGroupItem :value="PaymentScheduleType.FIXED">
                  <template #content>
                    <UITextSmMedium class="pr-20 payment-due-on">{{
                      $t('paymentSchedule.fixedAmountSchedule')
                    }}</UITextSmMedium>
                  </template>
                </UIRadioGroupItem>
              </UISpace>
            </UIRadioGroup>
          </div>

          <div
            v-if="paymentScheduleForm.paymentDueOn === PaymentDueOn.CUSTOM_DATE"
          >
            <UIFormItem
              :label="$t('paymentSchedule.invoiceDueDate')"
              rule-path="invoiceDueDateRule"
              :path="`paymentScheduleForm.dueDate`"
              class="flex-1"
            >
              <UIDatepicker
                id="invoice-due-date"
                v-model:formattedValue="paymentScheduleForm.dueDate"
                type="date"
                value-format="yyyy-MM-dd"
                :is-date-disabled="
                  date => dayjs(date).endOf('d').isBefore(dayjs())
                "
                class="w-full"
              />
            </UIFormItem>
          </div>

          <div class="grid mt-2">
            <div
              v-for="(schedule, idx) in paymentScheduleForm.schedules"
              :key="idx"
              class=""
            >
              <div
                class="flex items-center gap-2"
                :class="
                  !(
                    idx === 0 &&
                    (paymentScheduleForm?.paymentDueOn ===
                      PaymentDueOn.UPON_DOCUMENT_COMPLETE ||
                      paymentScheduleForm?.paymentDueOn ===
                        PaymentDueOn.CUSTOM_DATE)
                  )
                    ? 'mb-4'
                    : ''
                "
              >
                <UIFormItem
                  :path="`schedules.${idx}.value`"
                  :label="
                    $t('paymentSchedule.scheduleLabel', {
                      number: idx + 1,
                      total: paymentScheduleForm.schedules.length,
                    })
                  "
                  rule-path="valueRule"
                  class="flex-1 w-full"
                >
                  <UIInputNumber
                    id="schedule-value-btn"
                    :model-value="schedule.value"
                    @update:model-value="
                      onUpdateScheduleValue(schedule, $event)
                    "
                    :show-button="false"
                    class="schedule-value w-full"
                  >
                    <template #suffix>
                      <div
                        v-if="
                          paymentScheduleForm.type ===
                          PaymentScheduleType.PERCENTAGE
                        "
                      >
                        %
                      </div>
                      <div v-else>
                        {{ getCurrencySymbol(props.currencyCode) }}
                      </div>
                    </template>
                  </UIInputNumber>
                </UIFormItem>

                <UIFormItem
                  rule-path="dueDateRule"
                  :path="`schedules.${idx}.dueDate`"
                  class="flex-1 w-full custom-label"
                >
                  <div class="flex flex-col">
                    <div class="flex items-center due-date-padding">
                      <span class="mr-2">{{
                        $t('paymentSchedule.dueDateLabel')
                      }}</span>
                      <UITooltip
                        v-if="enableToolTipForDueDate(idx)"
                        :content="$t('paymentSchedule.signingDateDueDateInfo')"
                        :placement="'top'"
                      >
                        <template #trigger>
                          <InfoCircleIcon
                            class="w-4 h-4 text-gray-500 cursor-pointer"
                          />
                        </template>
                        {{ $t('paymentSchedule.signingDateDueDateInfo') }}
                      </UITooltip>
                    </div>
                    <div>
                      <UIInput
                        :id="`uponPrimarySignature-${idx}`"
                        :readonly="true"
                        class="upon-comp-input"
                        v-if="
                          idx === 0 &&
                          paymentScheduleForm?.paymentDueOn ===
                            PaymentDueOn.UPON_DOCUMENT_COMPLETE
                        "
                        :value="$t('paymentSchedule.uponPrimarySignature')"
                      >
                      </UIInput>
                      <UIInput
                        :id="`due-date-each-${idx}`"
                        :readonly="true"
                        v-else-if="
                          idx > 0 &&
                          paymentScheduleForm?.paymentDueOn ===
                            PaymentDueOn.UPON_DOCUMENT_COMPLETE &&
                          paymentScheduleForm?.billingScheduleType ===
                            BillingScheduleType.REGULAR
                        "
                        :value="dueDateTextUponCompletion()"
                      >
                      </UIInput>
                      <UIInput
                        :id="`due-date-each-custom-${idx}`"
                        v-else-if="
                          idx > 0 &&
                          paymentScheduleForm?.billingScheduleType ===
                            BillingScheduleType.REGULAR
                        "
                        class="w-full"
                        :value="formatTime(schedule, 'YYYY-MM-DD')"
                        :readonly="true"
                        placeholder=""
                        :disabled="isDueDateCustomDisabled"
                      >
                      </UIInput>
                      <UIDatepicker
                        v-else
                        id="schedule-date"
                        v-model:formattedValue="schedule.dueDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :is-date-disabled="
                          date => dayjs(date).endOf('d').isBefore(dayjs())
                        "
                        @update:value="onChangeDueDate(schedule, $event)"
                        class="w-full"
                      />
                    </div>
                  </div>
                </UIFormItem>
                <UIButton
                  id="delete-btn"
                  quaternary
                  circle
                  :disabled="paymentScheduleForm.schedules.length <= 2"
                  @click="deletePaymentSchedule(idx)"
                  class="ml-2"
                >
                  <Trash02Icon class="h-5 w-5 text-red-600" />
                </UIButton>
              </div>

              <div
                class="flex mb-6"
                v-if="
                  idx === 0 &&
                  (paymentScheduleForm?.paymentDueOn ===
                    PaymentDueOn.UPON_DOCUMENT_COMPLETE ||
                    paymentScheduleForm?.paymentDueOn ===
                      PaymentDueOn.CUSTOM_DATE)
                "
              >
                <Lightbulb02Icon class="h-4 w-4 mr-1 mb-1 inline-block" />
                <UITextSmRegular class="inline-block">{{
                  $t('paymentSchedule.uponCompletionInfo')
                }}</UITextSmRegular>
              </div>
            </div>
          </div>

          <div class="flex mb-2">
            <span
              @click="addPaymentSchedule"
              class="add-payment-btn cursor-pointer text-primary-600 font-semibold ml-auto"
            >
              {{ $t('paymentSchedule.addPaymentBtn') }}
            </span>
          </div>

          <div class="flex justify-between mb-2">
            <span>{{ $t('paymentSchedule.total') }}</span>
            <span class="font-semibold">{{ totalScheduleValue.text }}</span>
          </div>
          <div class="flex justify-between mb-2">
            <span class="text-gray-400">
              {{ $t('paymentSchedule.remainingValueText') }}</span
            >
            <span class="text-gray-400">{{ remainingValue.text }}</span>
          </div>

          <UIFormItem
            rule-path="invoiceTotalRule"
            path="schedules"
            :show-label="false"
            class="total-error"
          />
        </UIForm>
      </div>

      <div class="flex-1 w-6/12 p-4 bg-gray-100">
        <UITextMdMedium class="mb-3">{{
          $t('paymentSchedule.configurePayments')
        }}</UITextMdMedium>

        <div class="mt-5">
          <UITextSmMedium
            class="mb-1 text-sm text-gray-700 font-medium leading-5"
            >{{ $t('paymentSchedule.firstPaymentDueOn') }}</UITextSmMedium
          >
          <UIRadioGroup
            :value="paymentScheduleForm.paymentDueOn"
            :name="'paymentDueOn'"
            :disabled="false"
            :id="'paymentDueOn'"
            :size="'medium'"
            class="mt-4"
          >
            <UISpace
              :inline="false"
              :wrapItem="true"
              :justify="'start'"
              :vertical="false"
              :wrap="true"
            >
              <UIRadio
                :id="dueOn.value"
                v-for="dueOn in paymentDueOnOptions"
                :key="dueOn.value"
                :value="dueOn.value"
                :label="dueOn.label"
                @change="handlePaymentDueOnChange"
              />
            </UISpace>
          </UIRadioGroup>
        </div>

        <div class="mt-5">
          <UITextSmMedium
            class="mb-1 text-sm text-gray-700 font-medium leading-5"
            >{{ $t('paymentSchedule.subsequentPayments') }}</UITextSmMedium
          >

          <UIRadioGroup
            :value="paymentScheduleForm.billingScheduleType"
            :name="'billingSchedule'"
            :disabled="false"
            :id="'billingSchedule'"
            :size="'medium'"
            class="mt-4"
          >
            <UISpace
              :inline="false"
              :wrapItem="true"
              :justify="'start'"
              :vertical="false"
              :wrap="true"
            >
              <UIRadio
                :id="billingSchedule.value"
                v-for="billingSchedule in billingScheduleOptions"
                :key="billingSchedule.value"
                :value="billingSchedule.value"
                :label="billingSchedule.label"
                @change="handleBillingScheduleChange"
              />
            </UISpace>
          </UIRadioGroup>
        </div>

        <div
          class="mt-5"
          v-if="
            paymentScheduleForm?.billingScheduleType ===
            BillingScheduleType.REGULAR
          "
        >
          <div class="flex items-center">
            <UISpace
              :inline="false"
              :wrapItem="true"
              :justify="'start'"
              :vertical="false"
              :wrap="true"
            >
              <!-- <UITextSmMedium class="mt-2">-</UITextSmMedium> -->
              <UIInputNumber
                class="billing-interval"
                :id="'billing-schedule-regular-interval'"
                :placeholder="'Enter Number'"
                :size="'small'"
                :buttonPlacement="'both'"
                :min="1"
                :value="paymentScheduleForm?.billingSchedule?.interval"
                :on-update:value="onUpdateInterval"
              />
              <!-- <UITextSmMedium class="mt-2">+</UITextSmMedium> -->
              <UISelect
                class="billing-unit"
                id="billing-schedule-regular-unit"
                :value="paymentScheduleForm?.billingSchedule?.unit"
                :options="intervalTypes"
                :size="'medium'"
                :placeholder="'Select one'"
                @update:value="onUpdateRegularUnit"
              />
            </UISpace>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="flex justify-end">
        <UIButton id="cancel-btn" class="mr-2" @click="onCancelForms">
          {{ $t('common.cancel') }}</UIButton
        >
        <UIButton id="save-btn" type="primary" @click="addPaymentPlan">
          {{ $t('common.save') }}
        </UIButton>
      </div>
    </div>
  </CustomModal>
</template>

<style scoped lang="scss">
.billing-interval {
  width: 120px !important;
  ::v-deep(.n-input) {
    --n-height: 40px !important;
    --n-padding-left: 0px !important;
  }
}
.due-date-padding {
  padding-bottom: 4px !important;
}
.custom-label {
  --n-label-height: 0px !important;
}
.billing-unit {
  width: 120px !important;
  ::v-deep(.n-base-selection) {
    --n-height: 40px !important;
  }
}
.payment-type {
  .n-radio-button.hl-radio-group-item {
    // padding: 1.2em 2.5em;
  }
}
.add-payment-btn {
  color: #0040c1;
}
.total-error {
  --n-blank-height: 5px !important;
  --n-feedback-height: 10px !important;
}
.payment-due-on {
  padding-right: 2.5em;
}

.upon-comp-input {
  --n-font-size: 14px !important;
  --n-padding-right: 0px !important;
}
</style>
