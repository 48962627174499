import {
  Certificate,
  CheckBoxElementOptions,
  DateFieldElementOptions,
  DocumentStatus,
  ELEMENTS_LOOKUP,
  FillableField,
  GROUP_RULE_OPTION_VALUES,
  GroupFields,
  GroupFieldsOptions,
  IComponent,
  IDocumentViewer,
  IElement,
  IPayment,
  IRecipient,
  InitialsElementOptions,
  IntervalType,
  InvoiceType,
  LineItem,
  LinkReference,
  ProductListElementOptions,
  RecipientCategory,
  RecipientEntityTypeEnum,
  SignatureElementOptions,
  TextFieldElementOptions,
  TimeZoneEnum,
  UpdateSignatureParams,
  fillableFields,
  flatChildrens,
  isElementPartOfAnyGroup,
  isGroupRule,
  selectableFields,
} from '@gohighlevel/ghl-proposals-common'

import { RouteLocationNormalizedLoaded } from 'vue-router'
import { defineStore } from 'pinia'
import { inject } from 'vue'

export interface DocumentStoreGetters {
  fillableFieldsMap: Record<string, FillableField>
  activeRecipientId: string
  activeRecipient: IRecipient | null
  isAlreadyAccepted: boolean
  signatureElementIds: string[]
  unFilledElements: {
    elements: IElement<
      SignatureElementOptions | TextFieldElementOptions | GroupFieldsOptions
    >[]
    elementsPageMap: Record<string, number>
  }
  unsignedSignatureElementIds: string[]
  signatureElementCount: number
  signedSignatureElementCount: number
  signedSignatureCount: number
  signatureElementCountForRecipient: number
  signedSignatureElementCountForRecipient: number
  isCcRecipient: boolean
  hasRecipientSigned: boolean
  isEditable: boolean
  previousRecipients: IRecipient[]
  previousUnSignedRecipients: IRecipient[]
  hasPrevRecipientsCompleted: boolean
  isActiveRecipientPrimary: boolean
  assignedRequiredGroupCountForRecipient: number
  completedRequiredGroupCountForRecipient: number
  getGroupElementsMap: Record<string, IElement[]>
  assignedElementsForRecipient: IElement<
    SignatureElementOptions | TextFieldElementOptions | GroupFieldsOptions
  >[]
  assignedElementsForRecipientPageIndexMap: Record<string, number>
  isPaymentRequired: boolean
}

export interface DocumentStore extends DocumentStoreGetters {
  document: IDocumentViewer
  fillableFields: FillableField[]
  pages: IElement[]
  timezone?: {
    abbreviation: string
    zone: TimeZoneEnum
  } | null
  isEditing: boolean
  status: DocumentStatus
  recipients: IRecipient[]
  certificate: Certificate | null
  recipientLinkReference: LinkReference
  groups: GroupFields<GroupFieldsOptions>[]
  visitedOptionalElements: string[]
  invoiceType: InvoiceType
  intervalType: IntervalType
  paymentInfo: IPayment
}

export const useDocumentStore = defineStore('document', {
  state: (): DocumentStore =>
    ({
      fillableFields: [] as FillableField[],
      document: {} as IDocumentViewer,
      pages: [],
      timezone: {} as DocumentStore['timezone'],
      isEditing: false,
      status: DocumentStatus.SENT,
      recipients: [],
      certificate: null,
      recipientLinkReference: {} as LinkReference,
      paymentInfo: {} as IPayment,
      groups: [] as GroupFields<GroupFieldsOptions>[],
      visitedOptionalElements: [],
      invoiceType: InvoiceType.ONETIME,
      intervalType: IntervalType.DAILY,
    } as unknown as DocumentStore),
  getters: {
    fillableFieldsMap(state): Record<string, FillableField> {
      return state.fillableFields.reduce((acc, obj) => {
        acc[obj.id] = obj
        return acc
      }, {} as Record<string, FillableField>)
    },
    activeRecipientId(state): string {
      /**
       * @description This route is inject from the App.vue file
       * FYI: https://pinia.vuejs.org/core-concepts/#Defining-a-Store
       */
      const route = inject<RouteLocationNormalizedLoaded>('route')
      return (route?.params?.recipientId ||
        (state.recipients &&
          state.recipients.length &&
          state.recipients[0].id)) as string
    },
    activeRecipient(state) {
      /**
       *  @description This route is inject from the App.vue file
       *  FYI: https://pinia.vuejs.org/core-concepts/#Defining-a-Store
       */
      const route = inject<RouteLocationNormalizedLoaded>('route')
      if (
        route?.name === 'document' &&
        !state.recipientLinkReference?.recipientId &&
        route?.params?.recipientId
      ) {
        if (state.recipients.length === 1) {
          return state.recipients.find(
            ({ id }: IRecipient) => id === route?.params?.recipientId
          )
        }
      } else {
        return state.recipients.find(
          ({ id, entityName }: IRecipient) =>
            id === state.recipientLinkReference?.recipientId &&
            entityName === state.recipientLinkReference?.entityName
        )
      }
    },
    isActiveRecipientPrimary(state) {
      return state.activeRecipient?.isPrimary
    },
    isCcRecipient(state) {
      if (state.recipientLinkReference.recipientCategory) {
        return (
          state.recipientLinkReference.recipientCategory ===
            RecipientCategory.BCC ||
          state.recipientLinkReference.recipientCategory ===
            RecipientCategory.CC
        )
      }
      return false
    },
    signatureElementCount: state => {
      return flatChildrens(state.pages).filter(
        ({ type }) =>
          type === ELEMENTS_LOOKUP.SIGNATURE ||
          type === ELEMENTS_LOOKUP.INITIALS_FIELD
      ).length
    },
    signedSignatureCount(state) {
      return flatChildrens(state.pages)
        .filter(
          ({ type }) =>
            type === ELEMENTS_LOOKUP.SIGNATURE ||
            type === ELEMENTS_LOOKUP.INITIALS_FIELD
        )
        .filter((child: IElement) => {
          const recipient = state.recipients.find(
            elem =>
              elem.id === child.component.options.recipient &&
              elem.entityName === child.component.options.entityName &&
              elem.imgUrl &&
              elem.hasCompleted
          )
          return recipient
        }).length
    },
    signedSignatureElementCount(state) {
      return flatChildrens(state.pages).filter(
        element =>
          element.type === ELEMENTS_LOOKUP.SIGNATURE ||
          (element.type === ELEMENTS_LOOKUP.INITIALS_FIELD &&
            element.component.options.isSigned &&
            this.activeRecipient?.imgUrl)
      ).length
    },
    signatureElementCountForRecipient: state => {
      const count = flatChildrens(state.pages).filter(
        (
          element: IElement<SignatureElementOptions | TextFieldElementOptions>
        ) => {
          const { activeRecipient } = state
          const {
            type,
            component: { options },
          } = element
          if (
            element.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
            element.version > 1
          ) {
            const lineItems = (
              element.component as unknown as IComponent<ProductListElementOptions>
            ).options.lineItems
            if (
              lineItems &&
              lineItems.some(({ optional }) => optional) &&
              state.isActiveRecipientPrimary
            ) {
              return true
            } else {
              return false
            }
          }

          if (type === ELEMENTS_LOOKUP.CHECKBOX) {
            const group = isElementPartOfAnyGroup(state.groups, element.id)
            if (!group) {
              return (
                options.entityName === activeRecipient?.entityName &&
                options.recipient === activeRecipient?.id &&
                options.required
              )
            }
          }

          if (
            type === ELEMENTS_LOOKUP.SIGNATURE ||
            type === ELEMENTS_LOOKUP.INITIALS_FIELD
          ) {
            return (
              options.entityName === activeRecipient?.entityName &&
              options.recipient === activeRecipient?.id
            )
          }
          if (fillableFields.has(type)) {
            return (
              options.entityName === activeRecipient?.entityName &&
              options.recipient === activeRecipient?.id &&
              options.required
            )
          }
        }
      ).length
      const assignedGroupCount = state.assignedRequiredGroupCountForRecipient
      return count + assignedGroupCount
    },
    signedSignatureElementCountForRecipient(state) {
      const signedCount = flatChildrens(state.pages).filter(
        (
          element: IElement<
            | SignatureElementOptions
            | TextFieldElementOptions
            | InitialsElementOptions
          >
        ) => {
          const { activeRecipient } = state
          const {
            type,
            component: { options },
          } = element
          if (
            element.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
            element.version > 1
          ) {
            const lineItems = (
              element.component as unknown as IComponent<ProductListElementOptions>
            ).options.lineItems
            if (
              lineItems &&
              lineItems.some(({ optional }) => optional) &&
              state.isActiveRecipientPrimary
            ) {
              return true
            } else {
              return false
            }
          }
          if (type === ELEMENTS_LOOKUP.SIGNATURE) {
            return (
              options.entityName === activeRecipient?.entityName &&
              options.recipient === activeRecipient?.id &&
              options.isSigned &&
              activeRecipient.imgUrl
            )
          }
          if (type === ELEMENTS_LOOKUP.INITIALS_FIELD) {
            return (
              options.entityName === activeRecipient?.entityName &&
              options.recipient === activeRecipient?.id &&
              options.isSigned &&
              activeRecipient.initialsImgUrl
            )
          }

          if (type === ELEMENTS_LOOKUP.CHECKBOX) {
            const group = isElementPartOfAnyGroup(state.groups, element.id)
            if (!group) {
              return (
                options.entityName === activeRecipient?.entityName &&
                options.recipient === activeRecipient?.id &&
                options.required &&
                (options.text === 'true' ||
                  (options?.preChecked && options.text !== 'false'))
              )
            }
          }

          if (fillableFields.has(type)) {
            return (
              options.entityName === activeRecipient?.entityName &&
              options.recipient === activeRecipient?.id &&
              options.required &&
              options.text
            )
          }
          return false
        }
      ).length

      const completedGroupCount = state.completedRequiredGroupCountForRecipient
      return signedCount + completedGroupCount
    },
    hasRecipientSigned: state => {
      return state.recipients.every(({ imgUrl }) => imgUrl)
    },
    signatureElementIds: state => {
      return flatChildrens(state.pages)
        .filter(
          element =>
            element.type === ELEMENTS_LOOKUP.SIGNATURE ||
            element.type === ELEMENTS_LOOKUP.INITIALS_FIELD
        )
        .map(({ id }) => id)
    },
    unFilledElements: state => {
      const pushToAccumulator = (acc: any, element: IElement, idx: number) => {
        const {
          type,
          component: { options },
        } = element
        if (
          element.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
          element.version > 1
        ) {
          const lineItems = (
            element.component as unknown as IComponent<ProductListElementOptions>
          ).options.lineItems
          if (
            lineItems &&
            lineItems.some(({ optional }) => optional) &&
            state.isActiveRecipientPrimary
          ) {
            acc.elements.push(element)
            acc.elementsPageMap[element.id] = idx
          }
        }
        if (
          type === ELEMENTS_LOOKUP.SIGNATURE ||
          type === ELEMENTS_LOOKUP.INITIALS_FIELD
        ) {
          if (
            !(options as SignatureElementOptions).isSigned &&
            options.recipient === state.recipientLinkReference?.recipientId &&
            options.entityName === state.recipientLinkReference?.entityName
          ) {
            acc.elements.push(element)
            acc.elementsPageMap[element.id] = idx
          }
        }

        if (type === ELEMENTS_LOOKUP.CHECKBOX) {
          const group = isElementPartOfAnyGroup(state.groups, element.id)
          if (group) {
            if (
              group.component.options.recipient ===
                state.recipientLinkReference?.recipientId &&
              group.component.options.entityName ===
                state.recipientLinkReference?.entityName
            ) {
              const isGroupRuleSatisfied = isGroupRule(
                group,
                state.getGroupElementsMap
              )

              if (!groupMapWithElements[group.id] && !isGroupRuleSatisfied) {
                groupMapWithElements[group.id] = element
                acc.elements.push(group)
                acc.elementsPageMap[group.id] = idx
              }
            }
          } else {
            if (
              options.text !== 'true' &&
              options.required &&
              options.recipient === state.recipientLinkReference?.recipientId &&
              options.entityName === state.recipientLinkReference?.entityName
            ) {
              acc.elements.push(element)
              acc.elementsPageMap[element.id] = idx
            }
          }
        }

        if (fillableFields.has(type)) {
          if (
            !options.text &&
            options.required &&
            options.recipient === state.recipientLinkReference?.recipientId &&
            options.entityName === state.recipientLinkReference?.entityName
          ) {
            acc.elements.push(element)
            acc.elementsPageMap[element.id] = idx
          }
        }
      }
      const groupMapWithElements: Record<string, any> = {}
      const unfilledElem = state.pages.reduce(
        (acc, page, pageIdx) => {
          page.children?.forEach(
            (
              element: IElement<
                SignatureElementOptions | TextFieldElementOptions
              >
            ) => {
              if (element.type === ELEMENTS_LOOKUP.ROW) {
                element.children?.forEach(child => {
                  child.children?.forEach(el => {
                    pushToAccumulator(acc, el, pageIdx)
                    if (el.children && el.children.length > 0) {
                      el.children.forEach(nestedElem => {
                        pushToAccumulator(acc, nestedElem, pageIdx)
                        nestedElem.children?.forEach(nestedChild => {
                          pushToAccumulator(acc, nestedChild, pageIdx)
                        })
                      })
                    }
                  })
                })
              } else {
                pushToAccumulator(acc, element, pageIdx)
                element?.children?.forEach(child => {
                  pushToAccumulator(acc, child, pageIdx)
                })
              }
            }
          )
          return acc
        },
        { elements: [], elementsPageMap: {} } as {
          elements: IElement<
            | SignatureElementOptions
            | TextFieldElementOptions
            | GroupFieldsOptions
          >[]
          elementsPageMap: Record<string, number>
        }
      )
      return unfilledElem
    },
    unsignedSignatureElementIds: state => {
      return state.unFilledElements.elements.map(({ id }) => id)
    },
    isAlreadyAccepted: state => {
      return (
        state.status === DocumentStatus.ACCEPTED ||
        state.status === DocumentStatus.COMPLETED
      )
    },
    previousRecipients: state => {
      const recipient = state.activeRecipient as IRecipient
      return (
        state?.recipients?.filter(
          ({ signingOrder }) => signingOrder < recipient?.signingOrder
        ) || []
      )
    },
    previousUnSignedRecipients: state => {
      const recipient = state.activeRecipient as IRecipient
      return (
        state?.recipients?.filter(
          ({ signingOrder, hasCompleted }) =>
            signingOrder < recipient?.signingOrder && !hasCompleted
        ) || []
      )
    },
    hasPrevRecipientsCompleted: state => {
      if (!state?.document?.enableSigningOrder) {
        return true
      }
      if (state?.activeRecipient?.signingOrder === 1) {
        return true
      }
      return state?.previousRecipients?.every(
        ({ hasCompleted }) => hasCompleted
      )
    },
    isEditable: state => {
      return (
        state.status === DocumentStatus.SENT ||
        state.status === DocumentStatus.VIEWED ||
        state.status === DocumentStatus.WAITING_FOR_PAYMENT
      )
    },
    assignedRequiredGroupCountForRecipient: state => {
      // assigned group for the recipients count. (only required group)
      return state.groups.filter(g => {
        const rule = g?.component?.options?.rule
        const ruleValue = g?.component?.options?.ruleValue
        if (
          g.component?.options?.recipient === state.activeRecipient?.id &&
          g.component?.options?.entityName === state.activeRecipient?.entityName
        ) {
          if (rule && Number(ruleValue) > 0) {
            if (
              rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_LEAST ||
              rule === GROUP_RULE_OPTION_VALUES.SELECT_EXACTLY ||
              rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_MOST
            ) {
              return g
            }
          }
        }
      }).length
    },
    completedRequiredGroupCountForRecipient: state => {
      // get count for all assigned group including the optional group for the recipient
      return state.groups.filter(g => {
        if (
          g.component?.options?.recipient === state.activeRecipient?.id &&
          g.component?.options?.entityName === state.activeRecipient?.entityName
        ) {
          const rule = g?.component?.options?.rule
          const ruleValue = g?.component?.options?.ruleValue
          if (rule && ruleValue && Number(ruleValue) > 0) {
            const elements = state?.getGroupElementsMap[g.id]
            const completedElements = elements.filter(el => {
              if (
                el.type === ELEMENTS_LOOKUP.CHECKBOX &&
                el.component.options.text &&
                el.component.options.text === 'true'
              ) {
                return el
              }
            })

            if (
              rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_LEAST &&
              completedElements.length >= Number(ruleValue)
            ) {
              return g
            }

            if (
              rule === GROUP_RULE_OPTION_VALUES.SELECT_EXACTLY &&
              completedElements.length === Number(ruleValue)
            ) {
              return g
            }

            if (
              rule === GROUP_RULE_OPTION_VALUES.SELECT_AT_MOST &&
              completedElements.length <= Number(ruleValue)
            ) {
              return g
            }
          }
        }
      }).length
    },
    getGroupElementsMap(state) {
      const groupMapWithElements: Record<string, any> = {}
      state.groups.forEach(g => {
        const elementIds = g?.children?.map(({ elementId }) => elementId)
        groupMapWithElements[g.id] = state.pages.reduce((acc, page) => {
          page.children?.forEach(element => {
            if (elementIds.includes(element.id)) {
              acc.push(element)
            }
          })
          return acc
        }, [] as any[])
      })
      return groupMapWithElements
    },
    assignedElementsForRecipient(state) {
      const pushTpAccumulator = (acc: any, element: IElement) => {
        const {
          type,
          component: { options },
        } = element

        if (
          element.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
          element.version > 1
        ) {
          const lineItems = (
            element.component as unknown as IComponent<ProductListElementOptions>
          ).options.lineItems
          if (
            lineItems &&
            lineItems.some(({ optional }) => optional) &&
            state.isActiveRecipientPrimary
          ) {
            acc.push(element)
          }
        }

        if (type === ELEMENTS_LOOKUP.CHECKBOX) {
          const group = isElementPartOfAnyGroup(state.groups, element.id)
          if (group) {
            if (
              group.component.options.recipient ===
                state.recipientLinkReference?.recipientId &&
              group.component.options.entityName ===
                state.recipientLinkReference?.entityName
            ) {
              if (!groupMapWithElements[group.id]) {
                groupMapWithElements[group.id] = element
                acc.push(group)
              }
            }
          } else {
            if (
              options.recipient === state.recipientLinkReference?.recipientId &&
              options.entityName === state.recipientLinkReference?.entityName
            ) {
              acc.push(element)
            }
          }
        }

        if (
          type === ELEMENTS_LOOKUP.SIGNATURE ||
          type === ELEMENTS_LOOKUP.INITIALS_FIELD ||
          fillableFields.has(type)
        ) {
          if (
            options.recipient === state.recipientLinkReference?.recipientId &&
            options.entityName === state.recipientLinkReference?.entityName
          ) {
            acc.push(element)
          }
        }
      }
      const groupMapWithElements: Record<string, any> = {}
      return state.pages.reduce((acc, page) => {
        page.children?.forEach(
          (
            element: IElement<SignatureElementOptions | TextFieldElementOptions>
          ) => {
            pushTpAccumulator(acc, element)
            if (element.type === ELEMENTS_LOOKUP.ROW) {
              element.children?.forEach(child => {
                child.children?.forEach(el => {
                  pushTpAccumulator(acc, el)
                })
              })
            }
          }
        )
        return acc
      }, [])
    },
    assignedElementsForRecipientPageIndexMap: state => {
      const groupMapWithElements: Record<string, any> = {}
      return state.pages.reduce((acc, page, idx) => {
        page.children?.forEach(
          (
            element: IElement<SignatureElementOptions | TextFieldElementOptions>
          ) => {
            const {
              type,
              component: { options },
            } = element

            if (
              element.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
              element.version > 1
            ) {
              const lineItems = (
                element.component as unknown as IComponent<ProductListElementOptions>
              ).options.lineItems
              if (
                lineItems &&
                lineItems.some(({ optional }) => optional) &&
                state.isActiveRecipientPrimary
              ) {
                acc[element.id] = idx
              }
            }

            if (type === ELEMENTS_LOOKUP.CHECKBOX) {
              const group = isElementPartOfAnyGroup(state.groups, element.id)
              if (group) {
                if (
                  group.component.options.recipient ===
                    state.recipientLinkReference?.recipientId &&
                  group.component.options.entityName ===
                    state.recipientLinkReference?.entityName
                ) {
                  if (!groupMapWithElements[group.id]) {
                    groupMapWithElements[group.id] = element
                    acc[group.id] = idx
                  }
                }
              } else {
                if (
                  options.recipient ===
                    state.recipientLinkReference?.recipientId &&
                  options.entityName ===
                    state.recipientLinkReference?.entityName
                ) {
                  acc[element.id] = idx
                }
              }
            }

            if (
              type === ELEMENTS_LOOKUP.SIGNATURE ||
              type === ELEMENTS_LOOKUP.INITIALS_FIELD ||
              fillableFields.has(type)
            ) {
              if (
                options.recipient ===
                  state.recipientLinkReference?.recipientId &&
                options.entityName === state.recipientLinkReference?.entityName
              ) {
                acc[element.id] = idx
              }
            }
          }
        )
        return acc
      }, {})
    },
    isPaymentRequired(state) {
      const productListCount = flatChildrens(state.pages).filter(
        child =>
          child.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
          child.component.options?.lineItems?.length > 0
      ).length

      return productListCount > 0 && state.document.enableDirectPayment
    },
  },
  actions: {
    setDocumentData(document: IDocumentViewer) {
      this.pages = document.pages
      this.document = document
      this.timezone = document.timezone
      this.status = document.status as DocumentStatus
      this.recipients = document.recipients
      this.groups = document?.groups || []
    },
    getElementById(productId: string) {
      return flatChildrens(this.pages).find(
        (element: IElement) => element.id === productId
      )
    },
    updateSignatureElement(
      elementId: any,
      pageId: string,
      isSigned: boolean,
      dataImg?: string
    ) {
      const element = this.findElementInPageById(pageId, elementId)
      if (!element) return
      element.component.options.isSigned = isSigned
      if (dataImg) {
        element.component.options.src = dataImg
      }
    },
    updateSignatureElementAltText(
      elementId: any,
      pageId: string,
      signatureText?: string
    ) {
      const element = this.findElementInPageById(pageId, elementId)
      if (!element) return
      if (signatureText) {
        element.component.options.altText = signatureText
      }
    },
    updateSignatureForRecipient(
      recipientId: string,
      dataImg: string,
      dateStamp: string,
      isInitials = false
    ) {
      const recipient = this.recipients.find(({ id }) => id === recipientId)
      if (recipient) {
        if (isInitials) {
          recipient.initialsImgUrl = dataImg
        } else {
          recipient.imgUrl = dataImg
        }
        recipient.signedDate = dateStamp
      }
    },
    findElementInPageById(pageId: string, id: string) {
      const page = this.pages.find(page => page.id === pageId)
      if (!page) return

      const searchElement = (elements: IElement[]): IElement | undefined => {
        for (const element of elements) {
          if (element.id === id) {
            return element
          }

          if (element.children && element.children.length > 0) {
            const foundChild = searchElement(element.children)
            if (foundChild) {
              return foundChild
            }
          }
        }
        return undefined
      }

      return searchElement(page.children)
    },
    updateFillableElement(
      elementId: any,
      pageId: string,
      text: string,
      isFilled: boolean
    ) {
      const element = this.findElementInPageById(pageId, elementId)
      if (!element) return
      element.component.options.isFilled = isFilled
      element.component.options.text = text
      element.component.options.textFieldErrorMessage = ''
    },
    updateFillableFieldForRecipient(
      recipientId: string,
      text: string,
      fieldId: string,
      dateStamp: string
    ) {
      const recipient = this.recipients.find(({ id }) => id === recipientId)
      if (recipient) {
        const fieldRecord = recipient.fillableFields?.find(
          field => field.fieldId === fieldId
        )
        if (fieldRecord) {
          fieldRecord.fieldId = fieldId
          fieldRecord.filledDate = dateStamp
          fieldRecord.text = text
        } else {
          recipient.fillableFields?.push({
            fieldId,
            text,
            filledDate: dateStamp,
          })
        }
      }
    },
    setIsEditing(isEditing: boolean) {
      this.isEditing = isEditing
    },
    setDocumentStatus(status: DocumentStatus) {
      this.status = status
    },
    setCertificateDetails(data: Certificate) {
      this.certificate = data
    },
    setRecipientLinkReference(recipientLink: LinkReference) {
      this.recipientLinkReference = recipientLink
    },
    setPaymentInfo(paymentInfo: IPayment) {
      this.paymentInfo = paymentInfo
    },
    validateFillableFields(): boolean {
      const activeRecipientId = this.activeRecipient?.id
      let isAllValid = true

      for (const page of this.pages) {
        for (const child of page.children) {
          if (fillableFields.has(child.type)) {
            const options = child.component.options as unknown as
              | TextFieldElementOptions
              | DateFieldElementOptions

            if (options.recipient === activeRecipientId) {
              if (options.required && !options.text) {
                isAllValid = false
                options.textFieldErrorMessage = 'This field is Required'
              }
            }
          }
        }
      }
      return isAllValid
    },
    getFillableFields() {
      const getFields = (page: IElement, pageId: string) => {
        const fillableFieldsArray: UpdateSignatureParams['fillableFields'] = []

        const getFillableFieldsRecursive = (element: IElement) => {
          if (!element?.children) return

          element.children.forEach(child => {
            const option = child.component.options as
              | TextFieldElementOptions
              | CheckBoxElementOptions
              | SignatureElementOptions

            if (
              child.type === ELEMENTS_LOOKUP.SIGNATURE &&
              option.recipient === this.activeRecipient?.id
            ) {
              fillableFieldsArray.push({
                value: option.src ?? '',
                fieldId: option.fieldId ?? '',
                isRequired: option.required ?? true,
                recipient: option.recipient,
                hasCompleted: option.src ? true : false,
                entityType: option.entityName as RecipientEntityTypeEnum,
                id: child.id,
                type: child.type,
                pageId,
              })
            } else if (fillableFields.has(child.type)) {
              fillableFieldsArray.push({
                value: option.text ?? '',
                fieldId: option.fieldId ?? '',
                isRequired: option.required ?? false,
                recipient: option.recipient,
                hasCompleted: option.text ? true : false,
                entityType: option.entityName as RecipientEntityTypeEnum,
                id: child.id,
                type: child.type,
                pageId,
              })
            } else if (selectableFields.has(child.type)) {
              const group = isElementPartOfAnyGroup(this.groups, child.id)
              const value =
                option.text ||
                (!group && option?.preChecked
                  ? option.preChecked.toString()
                  : '')

              fillableFieldsArray.push({
                value,
                fieldId: option.fieldId ?? '',
                isRequired: option.required ?? false,
                recipient: option.recipient,
                hasCompleted: value ? true : false,
                entityType: option.entityName as RecipientEntityTypeEnum,
                id: child.id,
                type: child.type,
                ...(group && { groupId: group.id }),
              })
            }

            if (child.children && child.children.length > 0) {
              getFillableFieldsRecursive(child)
            }
          })
        }
        getFillableFieldsRecursive(page)
        return fillableFieldsArray
      }

      return this.pages.reduce((acc, page) => {
        if (page.children) {
          acc.push(...getFields(page, page.id))
        }
        return acc
      }, [] as UpdateSignatureParams['fillableFields'])
    },
    getPricingLineItems() {
      return flatChildrens(this.pages)
        .filter(child => child.type === ELEMENTS_LOOKUP.PRODUCT_LIST)
        .map(child => {
          return {
            version: child.version,
            id: child.id,
            lineItems: (
              child?.component as unknown as IComponent<ProductListElementOptions>
            )?.options?.lineItems,
          }
        })
    },
    updateLineItems(
      productListId: string,
      itemKey: string,
      key: keyof LineItem,
      value: any
    ) {
      const updateChild = (child: IElement) => {
        if (child.id === productListId) {
          child.component.options = {
            ...child?.component?.options,
            lineItems: child?.component?.options?.lineItems.map(item => {
              if (item.key === itemKey) {
                const updatedItem = {
                  ...item,
                  [key]: value,
                }
                return {
                  ...updatedItem,
                  subtotal: updatedItem.qty * updatedItem.price,
                }
              }
              return item
            }),
          }
        }
      }

      this.document?.pages?.forEach(page => {
        page.children.forEach(child => {
          updateChild(child)

          if (child.type === ELEMENTS_LOOKUP.ROW) {
            child.children.forEach(column => {
              column.children.forEach(updateChild)
            })
          }
        })
      })
    },
    setVisitedOptionalElement(id: string) {
      if (!this.visitedOptionalElements.includes(id))
        this.visitedOptionalElements.push(id)
    },
    removeVisitedOptionalElement(id: string) {
      if (this.visitedOptionalElements.includes(id)) {
        this.visitedOptionalElements = this.visitedOptionalElements.filter(
          value => value != id
        )
      }
    },
    getAllProductList() {
      return flatChildrens(this.pages).filter(
        element => element.type === ELEMENTS_LOOKUP.PRODUCT_LIST
      )
    },
    getFilledSignatureElementForRecipient(recipientId: string) {
      const result: {
        pageId: string
        element: IElement<SignatureElementOptions | InitialsElementOptions>
      }[] = []

      const traverseChildren = (children: IElement[], pageId: string) => {
        children.forEach(child => {
          const option = child.component.options as unknown as
            | SignatureElementOptions
            | InitialsElementOptions
          if (
            child.type === ELEMENTS_LOOKUP.SIGNATURE &&
            option.recipient === recipientId &&
            option.src
          ) {
            result.push({
              pageId,
              element: child,
            })
          }
          if (child.children && child.children.length > 0) {
            traverseChildren(child.children, pageId)
          }
        })
      }

      this.pages.forEach(page => {
        traverseChildren(page.children, page.id)
      })

      return result
    },
    validateElementUnFilled(element?: IElement) {
      if (!element) return false
      const {
        type,
        component: { options },
      } = element
      if (
        element.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
        element.version > 1
      ) {
        const lineItems = (
          element.component as unknown as IComponent<ProductListElementOptions>
        ).options.lineItems
        if (
          lineItems &&
          lineItems.some(({ optional }) => optional) &&
          this.isActiveRecipientPrimary
        ) {
          return true
        }
      }
      if (
        type === ELEMENTS_LOOKUP.SIGNATURE ||
        type === ELEMENTS_LOOKUP.INITIALS_FIELD
      ) {
        if (
          !(options as SignatureElementOptions).isSigned &&
          options.recipient === this.recipientLinkReference?.recipientId &&
          options.entityName === this.recipientLinkReference?.entityName
        ) {
          return true
        }
      }

      if (type === ELEMENTS_LOOKUP.CHECKBOX) {
        const group = isElementPartOfAnyGroup(this.groups, element.id)
        if (group) {
          if (
            group.component.options.recipient ===
              this.recipientLinkReference?.recipientId &&
            group.component.options.entityName ===
              this.recipientLinkReference?.entityName
          ) {
            const isGroupRuleSatisfied = isGroupRule(
              group,
              this.getGroupElementsMap
            )

            if (!isGroupRuleSatisfied) {
              return true
            }
          }
        } else {
          if (
            options.text !== 'true' &&
            options.required &&
            options.recipient === this.recipientLinkReference?.recipientId &&
            options.entityName === this.recipientLinkReference?.entityName
          ) {
            return true
          }
        }
      }

      if (fillableFields.has(type)) {
        if (
          !options.text &&
          options.required &&
          options.recipient === this.recipientLinkReference?.recipientId &&
          options.entityName === this.recipientLinkReference?.entityName
        ) {
          return true
        }
      }
      return false
    },
    setInvoiceTypeAndIntervalType(
      invoiceType: InvoiceType,
      intervalType: IntervalType
    ) {
      this.invoiceType = invoiceType
      this.intervalType = intervalType
    },
  },
})
