<script setup lang="ts">
import { PropType, computed } from 'vue'
import { IElement } from '@gohighlevel/ghl-proposals-common'
import CWrapper from './CWrapper.vue'
const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
})

const customClassesForImg = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return [styles?.align, styles?.imageEffect]
})

const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    backgroundColor: styles?.backgroundColor,
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
    // height: styles?.height ? styles?.height + 'px' : 'inherit',
    // width: styles?.width + 'px',
  }
})
</script>

<template>
  <CWrapper
    class="el-wrapper img-el print:break-inside-void break-inside-avoid"
  >
    <div :style="customStyleForImageParent" class="wrapper">
      <img
        :src="element?.component?.options?.src"
        :alt="element?.component?.options?.altText"
        class="img"
        :class="customClassesForImg"
        :height="props.element?.responsiveStyles.large?.height"
        :width="props.element?.responsiveStyles.large?.width"
      />
    </div>
  </CWrapper>
</template>

<style lang="scss">
.el-wrapper {
  .img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}
</style>
