<script setup lang="ts">
import {
  useDocument,
  useFillableFields,
  useMobileView,
  useSignature,
} from '@/composable'
import { useDocumentStore } from '@/store/document'
import { UIProgress, UITextXsRegular, UIButton } from '@gohighlevel/ghl-ui'
import { computed, inject, onBeforeUnmount, onMounted } from 'vue'

const store = useDocumentStore()
const isPreview = inject<boolean>('isPreview')
const isCCrecipient = computed(() => store.isCcRecipient)
const isNextDisabled = computed(() => isPreview || isCCrecipient.value)
const { signedCount, signElementCount, onFinishBtnClick, finishBtnText } =
  useSignature()
const { loading } = useDocument()
const isFinishBtnDisabled = computed(
  () =>
    loading.value ||
    signedCount.value !== signElementCount.value ||
    isCCrecipient.value
)
const { isMobile } = useMobileView()
const completedPercentage = computed(() => {
  return (signedCount.value / signElementCount.value) * 100
})
const { next } = useFillableFields()
const handleTabPress = (event: KeyboardEvent) => {
  if (event.key === 'Tab') {
    event.preventDefault()
    next()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleTabPress)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleTabPress)
})
</script>

<template>
  <div class="flex items-center flex-1">
    <div class="flex flex-1 flex-col" :style="{ width: '70%' }">
      <UIProgress
        :percentage="completedPercentage"
        indicatorPlacement="outside"
        type="line"
        :strokeWidth="7"
        :showIndicator="false"
        :processing="signedCount !== signElementCount"
        :indicatorTextColor="undefined"
      />
      <UITextXsRegular class="text-primary-700 mt-1">
        <strong>{{ signedCount }}/{{ signElementCount }}</strong>
        {{ $t('documentViewer.filled') }}
      </UITextXsRegular>
    </div>
    <div class="flex ml-4" v-if="!isMobile">
      <UIButton
        id="start-filling-doc-btn"
        type="primary"
        class="hover:bg-primary-400 shadow-lg"
        @click="onFinishBtnClick"
        :loading="loading"
        :disabled="isFinishBtnDisabled"
      >
        {{ finishBtnText }}
      </UIButton>
    </div>
    <div v-else class="flex ml-4">
      <UIButton
        v-if="signedCount === signElementCount"
        id="start-filling-doc-btn"
        type="primary"
        class="hover:bg-primary-400 shadow-lg"
        @click="onFinishBtnClick"
        :loading="loading"
        :disabled="isFinishBtnDisabled"
      >
        {{ finishBtnText }}
      </UIButton>
      <UIButton
        v-else
        id="next-btn"
        type="tertiary"
        class="!bg-yellow-500 hover:bg-yellow-400 shadow-lg !text-gray-900"
        @click="next"
        :loading="loading"
        :disabled="isNextDisabled"
      >
        {{ $t('documentViewer.next') }}
      </UIButton>
    </div>
  </div>
</template>
